<app-loading [show]="loading"></app-loading>
<div class="cart desktop-version">
  <div class="pl-3 pr-3">
    <div class="cart-section" *ngIf="!consolidatedOffer || consolidatedOffer.ArrangementAmount == 0">
      <h4 class="title-cart-empty">{{'AGREEMENTS.CART.NO-SELECTED-TITLE' | translate}}</h4>
      <p>{{'AGREEMENTS.CART.NO-SELECTED-SUBTITLE' | translate}}</p>
    </div>
    <div class="cart-section" *ngIf="consolidatedOffer && consolidatedOffer.ArrangementAmount != 0">
      <h2 class="title">{{'AGREEMENTS.CART.TITLE' | translate}}</h2>
      <p>{{'AGREEMENTS.CART.SUBTITLE' | translate}}</p>

      <div class="default-date" (click)="d.toggle();">
        <label for="dp"> <img class="icon-calendar"
            src="../../../assets/images/icon-calendar.png">{{'AGREEMENTS.CART.DATE'
          | translate }}</label>
        <input id="end-date-in-cash" class="date " placeholder=" " [markDisabled]="disabledDates" name="dp"
          ngbDatepicker #d="ngbDatepicker" [(ngModel)]="selectedDate" [autoClose]="'true'" [placement]="'bottom'"
          [minDate]="minDate" [maxDate]="maxDate" readonly (dateSelect)="updateSelectedDate($event)" />

        <span>{{selectedDate | date: 'dd/MM'}}</span>
      </div>
      <span class="warning"><img class="icon-alert "
          src="../../../assets/images/field-error.svg">{{'AGREEMENTS.CART.WARNING' | translate}}</span>

      <hr *ngIf="consolidatedOffer">
      <div class="scroll-bar" *ngIf="consolidatedOffer && consolidatedOffer.ArrangementAmount != 0">
        <ngb-accordion [closeOthers]="true" activeIds="acc-2" class="range-accordion">
          <ngb-panel *ngFor="let item of selectedDebts" class="debtid-{{item.DebtId}}">
            <ng-template ngbPanelTitle>
              <div class="row collapse-item">
                <div class="col-md-5 d-flex p-0 port-name"><span class="cart-field-list">{{item.PortfolioName}}</span>
                </div>
                <div class="col-md-3 d-flex p-0 justify-content-end"><span class="cart-field-list">{{
                    item.CurrentBalance | currency:
                    'BRL':'R$':'1.2-2' }}</span></div>
                <div class="col-md-3 d-flex p-0 justify-content-end"> <span
                    class="cart-field-list discount">{{item.OfferDiscount}}</span>
                </div>
                <div class="col-md-1 d-flex p-0 justify-content-end"> <img class="arrow"
                    src="../../../assets/images/arrow.png">
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="flex-container">
                <div>{{'AGREEMENTS.CART.CONTRACT' | translate}}</div>
                <div>{{'AGREEMENTS.CART.ORIGIN' | translate}}</div>
                <div>{{'AGREEMENTS.CART.ORIGIN-DATE' | translate}}</div>

              </div>
              <div class="flex-container">
                <div><b>{{item.DebtNumber}}</b></div>
                <div><b>{{item.PicPortfolioName}}</b></div>
                <div><b>{{item.FirstDefaultDate | date: 'dd/MM/yyyy'}}</b></div>
              </div>
              <div class="mt-5">
                <div class="flex-container">
                  <div>{{'AGREEMENTS.CART.DELAY' | translate}}</div>
                  <div>{{'AGREEMENTS.CART.FIRST-VALUE' | translate}}</div>
                  <div>{{'AGREEMENTS.CART.CURRENT-VALUE' | translate}}</div>

                </div>
                <div class="flex-container">
                  <div><b>{{item.delay}}</b></div>
                  <div><b>{{ item.DebtTotalBalance | currency: 'BRL':'R$':'1.2-2' }}</b></div>
                  <div><b>{{ item.CurrentBalance | currency: 'BRL':'R$':'1.2-2' }}</b></div>

                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
      <hr *ngIf="consolidatedOffer && consolidatedOffer.ArrangementAmount != 0">
      <div class="cart-summary">
        <div class="cart_menu__list">
          <section class="cart_menu__row">
            <span class="text-cart">{{'AGREEMENTS.CART.VALUE' | translate}}</span>
            <span class="dot"></span>
            <span class="cart_menu__price text-cart">{{(consolidatedOffer?.TotalBalance || 0) | currency:
              'BRL':'R$':'1.2-2'
              }}</span>
          </section>

          <section class="cart_menu__row">
            <span class="text-cart">{{'AGREEMENTS.CART.DISCOUNT' | translate}}</span>
            <span class="dot"></span>
            <span class="discount">{{consolidatedOffer?.OfferDiscount || '0%'}} off </span>
          </section>
          <section class="cart_menu__row_inline">
            <span class="cart_menu__price text-cart">À vista</span>
          </section>
          <section class="cart_menu__row">
            <span class="text-cart">{{'AGREEMENTS.CART.TOTAL' | translate}}</span>
            <span class="dot"></span>
            <p class="price">{{(consolidatedOffer?.ArrangementAmount || 0) | currency:
              'BRL':'R$':'1.2-2'}}</p>
          </section>
          <section>
            <button [disabled]="!consolidatedOffer" *ngIf="router.url !='/pagamento' " class="btn btn-primary"
              id="btn-agreement-next" routerLink="/pagamento">
              {{ 'AGREEMENTS.CART.PAYMENT' | translate }}
              <em class="fa fa-arrow-right fa-1x"></em>
            </button>
            <button [disabled]="paymentMethod === null || offerId === null" *ngIf="router.url =='/pagamento' "
              class="btn btn-primary" id="btn-agreement-next" (click)="openModal(modalConfirm)">
              {{ 'AGREEMENTS.PAYMENT.CONFIRMATION-TITLE' |
              translate }}
              <em class="fa fa-arrow-right fa-1x"></em>
            </button>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mobile-version">
  <app-swiper-bottom>
    <div class="pl-3 pr-3">
      <div class="cart-section" *ngIf="!consolidatedOffer">
        <h4 class="title-cart-empty">{{'AGREEMENTS.CART.NO-SELECTED-TITLE' | translate}}</h4>
        <p>{{'AGREEMENTS.CART.NO-SELECTED-SUBTITLE' | translate}}</p>
      </div>
      <div class="cart-section" *ngIf="consolidatedOffer">
        <h2 class="title">{{'AGREEMENTS.CART.TITLE' | translate}}</h2>
        <p>{{'AGREEMENTS.CART.SUBTITLE' | translate}}</p>

        <div class="default-date" (click)="d.toggle()" id="mobile-cart">
          <label for="dp"> <img class="icon-calendar"
              src="../../../assets/images/icon-calendar.png">{{'AGREEMENTS.CART.DATE'
            | translate }}</label>
          <input id="end-date-in-cash" class="date " placeholder=" " [markDisabled]="disabledDates" name="dp"
            ngbDatepicker #d="ngbDatepicker" [autoClose]="'true'" [placement]="'bottom-end'" [minDate]="minDate"
            [maxDate]="maxDate" readonly (dateSelect)="updateSelectedDate($event)" />

          <span>{{selectedDate | date: 'dd/MM'}}</span>
        </div>
        <span class="warning"><img class="icon-alert "
            src="../../../assets/images/field-error.svg">{{'AGREEMENTS.CART.WARNING' | translate}}</span>

        <hr *ngIf="consolidatedOffer">
        <div class="scroll-bar" *ngIf="consolidatedOffer" (dragover)="preventDefault($event)"
          (touchover)="preventDefault($event)">
          <ngb-accordion [closeOthers]="true" activeIds="acc-2" class="range-accordion">
            <ngb-panel *ngFor="let item of selectedDebts" class="debtid-{{item.DebtId}}">
              <ng-template ngbPanelTitle>
                <div class="row collapse-item">
                  <div class="col-4 d-flex p-0 port-name"><span class="cart-field-list">{{item.PortfolioName}}</span>
                  </div>
                  <div class="col-4 desktop-version justify-content-end"><span class="cart-field-list">{{
                      item.CurrentBalance
                      | currency:
                      'BRL':'R$':'1.2-2' }}</span></div>
                  <div class="col-4 p-0 mobile-version justify-content-end"><span class="cart-field-list">{{
                      item.CurrentBalance |
                      currency:
                      'BRL':'R$':'1.2-2' }}</span></div>
                  <div class="col-1 d-flex p-0 justify-content-end"> <span
                      class="cart-field-list discount justify-content-end">{{item.OfferDiscount}}</span>
                  </div>
                  <div class="col-1 d-flex justify-content-end p-0"> <img class="arrow"
                      src="../../../assets/images/arrow.png">
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row">
                  <div class="col pt-2">
                    <div class="nowrap">{{'AGREEMENTS.CART.CONTRACT' | translate}}</div>
                    <div class="nowrap"><b>{{item.DebtNumber}}</b></div>
                  </div>
                  <div class="col pt-2">
                    <div class="nowrap">{{'AGREEMENTS.CART.ORIGIN' | translate}}</div>
                    <div class="nowrap"><b>{{item.PicPortfolioName}}</b></div>
                  </div>
                  <div class="col pt-2">
                    <div class="nowrap">{{'AGREEMENTS.CART.ORIGIN-DATE' | translate}}</div>
                    <div><b>{{item.FirstDefaultDate | date: 'dd/MM/yyyy'}}</b></div>
                  </div>
                  <div class="col pt-2">
                    <div class="nowrap">{{'AGREEMENTS.CART.DELAY' | translate}}</div>
                    <div><b>{{item.delay}}</b></div>
                  </div>
                  <div class="col pt-2">
                    <div class="nowrap">{{'AGREEMENTS.CART.FIRST-VALUE' | translate}}</div>
                    <div><b>{{ item.DebtTotalBalance | currency: 'BRL':'R$':'1.2-2' }}</b></div>
                  </div>
                  <div class="col pt-2">
                    <div class="nowrap">{{'AGREEMENTS.CART.CURRENT-VALUE' | translate}}</div>
                    <div><b>{{ item.CurrentBalance | currency: 'BRL':'R$':'1.2-2' }}</b></div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
        <hr *ngIf="consolidatedOffer">
        <div class="cart-summary">
          <div class="cart_menu__list">
            <section class="cart_menu__row">
              <span class="text-cart">{{'AGREEMENTS.CART.VALUE' | translate}}</span>
              <span class="dot"></span>
              <span class="cart_menu__price text-cart">{{(consolidatedOffer?.TotalBalance || 0) | currency:
                'BRL':'R$':'1.2-2'
                }}</span>
            </section>

            <section class="cart_menu__row">
              <span class="text-cart">{{'AGREEMENTS.CART.DISCOUNT' | translate}}</span>
              <span class="dot"></span>
              <span class="discount">{{consolidatedOffer?.OfferDiscount || '0%'}} off </span>
            </section>
            <section class="cart_menu__row_inline">
              <span class="cart_menu__price text-cart">À vista</span>
            </section>
            <section class="cart_menu__row">
              <span class="text-cart">{{'AGREEMENTS.CART.TOTAL' | translate}}</span>
              <span class="dot"></span>
              <p class="price">{{(consolidatedOffer?.ArrangementAmount || 0) | currency:
                'BRL':'R$':'1.2-2'}}</p>
            </section>
            <section>
              <button [disabled]="!consolidatedOffer" *ngIf="router.url !='/pagamento' " class="btn btn-primary"
                id="btn-agreement-next" routerLink="/pagamento">
                {{ 'AGREEMENTS.CART.PAYMENT' | translate }}
                <em class=" fa fa-arrow-right fa-1x"></em>
              </button>

              <button disabled="paymentMethod === null || offerId === null" *ngIf="router.url =='/pagamento' "
                class="btn btn-primary hide-if-mobile" id="btn-agreement-finish" (click)="openModal(modalConfirm)">
                {{ 'AGREEMENTS.PAYMENT.CONFIRMATION-TITLE' | translate }}
                <em class="fa fa-arrow-right fa-1x"></em>
              </button>

            </section>
          </div>
        </div>
      </div>
    </div>
  </app-swiper-bottom>
</div>

<!-- Modais de conclusão -->
<ng-template #modalConfirm let-modal>
  <div class="modal-header">
    <h4 class="modal-title"><strong class="strong-title">{{ 'AGREEMENTS.PAYMENT.CONFIRMATION-TITLE' | translate
        }}</strong></h4>
    <img class="close-btn" (click)="modal.dismiss()" src="../../../assets/images/close-circle.png">
  </div>

  <div class="modal-body">
    <div class="modal-text">
      <p>{{ 'AGREEMENTS.PAYMENT.CONFIRMATION-BODY' | translate }}</p>
    </div>
    <div class="button-container">
      <button class="btn btn-primary d-block" (click)="closeAgreement(modalFinished);">{{
        'AGREEMENTS.PAYMENT.CONFIRMATION-BTN' |
        translate }}</button>
      <button class="cancel-btn" (click)="modal.dismiss()">{{ 'AGREEMENTS.PAYMENT.CANCEL' | translate }}</button>
    </div>

  </div>
</ng-template>


<ng-template #modalFinished let-modal>
  <img src="../../../assets/images/finish.png" alt="Imagem">
  <div class="modal-header">
    <h4 class="modal-title">
      <strong class="strong-title">{{ 'AGREEMENTS.PAYMENT.FINISH-TITLE' | translate }}
      </strong>
    </h4>
  </div>
  <div class="modal-body">
    <div class="modal-text mb-5">
      {{ 'AGREEMENTS.PAYMENT.FINISH-SUBTITLE' | translate }}
    </div>
    <button class="btn btn-primary d-block" (click)="modal.dismiss()" routerLink="/acordos">{{ 'AGREEMENTS.PAYMENT.KEEP'
      | translate }}</button>

  </div>
</ng-template>