import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  NO_ERRORS_SCHEMA,
  LOCALE_ID,
  ErrorHandler,
  APP_INITIALIZER,
} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GtagModule } from 'angular-gtag';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  NgbCollapseModule,
  NgbDateParserFormatter,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { FactoryOrValue } from 'rxjs';

import { SharedModule } from './shared/shared.module';
import { PublicModule } from './shared/components/public/public.module';

// auxiliary
import localePt from '@angular/common/locales/pt';
import { environment } from 'src/environments/environment';
import { NgbCustomDateParserFormatter } from './shared/utils/date-formatter';

// Crash Monitor
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from 'angularx-social-login';

// IdleKeepAlive
// this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { CustomTranslateLoader } from 'src/assets/i18n/translateLoader';
import { PaymentComponent } from './logged/payment/payment.component';

Bugsnag.start({
  apiKey: environment.bugsnagApiKey,
  appVersion: 'v1.2.0',
});
registerLocaleData(localePt);

export function HttpLoaderFactory(http: HttpClient): FactoryOrValue<any> {
  let random = Math.floor(Math.random() * 1000000000000000);
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    `.json?nocache=${random}`
  );
}

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    NgIdleKeepaliveModule.forRoot(),
    NgBootstrapFormValidationModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxYoutubePlayerModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'pt-br',
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    PublicModule,
    ReactiveFormsModule,
    GtagModule.forRoot({
      trackingId: environment.gtmId,
      trackPageviews: true,
    }),
    GoogleTagManagerModule.forRoot({
      id: environment.GTMMetricId,
    }),
    BrowserAnimationsModule,
    NgbCollapseModule,
    SocialLoginModule,
  ],
  providers: [
    PaymentComponent,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: NgbDateParserFormatter,
      useValue: new NgbCustomDateParserFormatter('DD/MM/YYYY'),
    },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleAppId),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAppId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule { }
