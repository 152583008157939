import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-coolies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: ['./cookies-policy.component.scss']
})
export class CookiesPolicyComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }
  close(event) {
    event.preventDefault();
    event.stopPropagation();
    this.activeModal.close();
  }

}
