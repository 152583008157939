import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private translate: TranslateService,
  ) {
  }

  anonymPhone(value: string) {
    if (value) {
      return value.replace(/\d(?!\d{0,1}$)/g, "*")
    }
    return value;
  }

  customDateAdapter = (value) => {
    return (
      value.year +
      '/' +
      ('0' + value.month).slice(-2) +
      '/' +
      ('0' + value.day).slice(-2)
    );
  };
  customDateAdapterMonth = (value) => {
    return (
      ('0' + value.month).slice(-2) +
      '/' +
      ('0' + value.day).slice(-2)
    );
  };

  concatLabel(value, description) {
    const measure = {
      "YEARS": "Anos",
      "MONTHS": "Meses",
      "DAYS": "Dias",
      "YEAR": "Ano",
      "MONTH": "Mês",
      "DAY": "Dia"
    };
    if (isNaN(value)) {
      return '';
    } else {
      return `${value} ${measure[description]}`;
    }
  }

  getDelayDate(item) {
    let today = moment();
    let day = moment(item.FirstDefaultDate);
    if (today.diff(day, 'days') <= 30) {
      return this.concatLabel(
        today.diff(day, 'days'),
        today.diff(day, 'days') > 1 ? 'DAYS' : 'DAY'
      );
    } else if (today.diff(day, 'months') <= 12) {
      return this.concatLabel(
        today.diff(day, 'months'),
        today.diff(day, 'months') > 1 ? 'MONTHS' : 'MONTH'
      );
    } else {
      return this.concatLabel(
        today.diff(day, 'years'),
        today.diff(day, 'years') > 1 ? 'YEARS' : 'YEAR'
      );
    }
  }

  addBusinessDays(date: moment.Moment, amountDays: number) {
    let newDate = date.clone();
    newDate.add(amountDays, 'days');

    if (newDate.isoWeekday() == 6) {
      newDate.add(2, 'days');


    } else if (newDate.isoWeekday() == 7) {
      newDate = newDate.add(1, "days");
    }

    return newDate;
  }
}
