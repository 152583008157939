import { Component, ViewChild, ElementRef, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-swiper-bottom',
  templateUrl: './swiper-bottom.component.html',
  styleUrls: ['./swiper-bottom.component.scss']
})
export class SwiperBottomComponent implements OnDestroy {

  @ViewChild('swiperBottom') swiperBottom: ElementRef<any>;
  full: boolean = false;
  dragDirection = 999999;

  constructor(
  ) {
    document.getElementsByTagName("html")[0].style.overflow = "auto";
  }

  ngOnDestroy(): void {
    document.getElementsByTagName("html")[0].style.overflow = "auto";
  }

  saveFistPositionDrag(event) {
    this.dragDirection = event.screenY;
  }

  saveFistPositionTouch(event) {
    this.dragDirection = event.touches[0]?.pageY;
  }

  dragOver(event) {
    let positionY = event.screenY;
    this.calcPosition(positionY, event);
  }

  touchOver(event) {
    let positionY = event.touches[0]?.pageY || 0;
    this.calcPosition(positionY, event);
  }

  calcPosition(positionY: number, event) {
    if (!event.target.closest(".scroll-bar")) {
      if (!this.full) {
        this.makeFull(true);
      } else if (this.dragDirection != positionY) {
        if (this.dragDirection > positionY) {
          this.makeFull(false);
        }
        if (this.dragDirection < positionY) {
          this.makeFull(true);
        }
      }
    }
  }

  makeFull(full: boolean) {
    if (full) {
      this.full = true;
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    } else {
      this.full = false;
      document.getElementsByTagName("html")[0].style.overflow = "auto";
    }
  }

  preventEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  onClick() {
    if (!this.full) {
      this.makeFull(true);
    }
  }
}
