import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Debt } from '../shared/models/debt.model';
import { Discharge } from '../shared/models/discharge.model';
import { Negotiation } from '../shared/models/negotiation.model';
import { InstallmentBill } from '../shared/models/installment-bill.model';
import { InstallmentPayment } from '../shared/models/installment-payment.model';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root',
})
export class DebtService extends AbstractService {
  model = 'debts';

  getDebts() {
    return this.http.get<any[]>(`${environment.apiUrl}/${this.model}`);
  }

  getAllDebts() {
    return this.http.get<any[]>(`${environment.apiUrl}/debts`);
  }

  getActiveDebts() {
    return this.http.get<any[]>(
      `${environment.apiUrl}/${this.model}?DebtStatus=ACTIVE`
    );
  }

  getNegotiations(bindingId, date?) {
    return this.http.get<Negotiation[]>(
      `${environment.apiUrl}/${this.model}/${bindingId}/negotiations`,
      { params: { FirstInstallmentDate: date } }
    );
  }

  getDischarge(bindingId) {
    return this.http.get<Discharge>(
      `${environment.apiUrl}/${this.model}/${bindingId}/discharge`
    );
  }

  getDischards() {
    return this.http.get<Discharge[]>(
      `${environment.apiUrl}/${this.model}/discharges`
    );
  }

  getInstalmmentsBills() {
    return this.http.get<InstallmentBill[]>(
      `${environment.apiUrl}/${this.model}/installments/bill`
    );
  }

  getInstalmmentsPayments() {
    return this.http.get<InstallmentPayment>(
      `${environment.apiUrl}/${this.model}/installments/payments`
    );
  }

  acceptDebt(bindingId, negotiationOfferId, data) {
    return this.http.patch(
      `${environment.apiUrl}/${this.model}/${bindingId}/negotiations/${negotiationOfferId}`,
      data
    );
  }

  generateDischarge(data) {
    return this.http.post(
      `${environment.apiUrl}/${this.model}/discharge`,
      data
    );
  }

  generateAndEmailDischarge(bindingId, data) {
    return this.http.post(
      `${environment.apiUrl}/${this.model}/${bindingId}/discharge/email`,
      data
    );
  }
  validateDebtCoupon(bindingId, couponID): any {
    const headers = new HttpHeaders().set('X-Api-Key', environment.API_KEY);
    return this.http.get(
      `${environment.apiUrl}/debts/${bindingId}/validatecoupon/${couponID}`,
      { headers }
    );
  }
  NegotiationOffersCoupon(bindingId, couponID?, date?): any {
    const headers = new HttpHeaders().set('X-Api-Key', environment.API_KEY);
    return this.http.get(
      `${environment.apiUrl}/debts/${bindingId}/negotiationscoupon/${couponID}?FirstInstallmentDate=${date}`,
      { headers }
    );
  }

  getPaymentMethods() {
    return this.http.get(
      `${environment.apiUrl}/debts/paymentmethods`
    );
  }
  // NegotiationOffersCoupon(bindingId, date?) {
  //   return this.http.get<Negotiation[]>(
  //     `${environment.apiUrl}/${this.model}/${bindingId}/negotiations`,
  //     { params: { FirstInstallmentDate: date } }
  //   );
  // }

  GetMultipleDebtsOffer(date, id) {
    // var params = new URLSearchParams();
    // params.set('debtIds', id);

    return this.http.get(
      `${environment.apiUrl}/debts/listoffers?date=${date}&`, { params: { debtIds: id } }
    ).toPromise();

  }

  closeAgreement(data) {
    return this.http.post(
      `${environment.apiUrl}/arrangements/submitmultiplearrangement`,
      data
    );
  }
}
