import { Component, HostListener, Inject, Renderer2, ViewChild } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TermsAndServicesComponent } from '../../terms-and-services/terms-and-services.component';
import { PrivacyCenterComponent } from '../../privacy-center/privacy-center.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { DOCUMENT } from '@angular/common';
import { CookiesPolicyComponent } from '../../cookies-policy/cookies-policy.component';

@Component({
  templateUrl: './footer.component.html',
  styleUrls: ['footer.component.scss'],
  selector: 'app-footer',
  providers: [NgbModalConfig, NgbModal]

})
export class FooterComponent {
  isLogged: boolean;

  public screenWidth: any;
  public screenHeight: any;

  year: number = new Date().getFullYear();

  @ViewChild('ra') public ra: any;

  constructor(
    private modalService: NgbModal,
    private gtmService: GoogleTagManagerService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document
  ) {
    const returnIpanema = JSON.parse(sessionStorage.getItem('returnIpanema'));
    this.isLogged = returnIpanema ? true : false;
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }



  ngAfterViewInit() {
    this.bindRA();
  }

  bindRA() {
    var s = this.renderer.createElement("script");
    s.type = "text/javascript";
    s.src = "https://s3.amazonaws.com/raichu-beta/selos/bundle.js";
    s.id = "ra-embed-reputation";
    s.dataset.id = 'MTk1NjU4OmZpZGMtaXBhbmVtYS12aQ==';
    s.dataset.target = 'reputation-ra';
    s.dataset.model = '2';

    this.renderer.appendChild(this.ra.nativeElement, s);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    console.log(this.screenWidth)
  }

  openModalTerms() {
    this.modalService.open(TermsAndServicesComponent,
      { windowClass: 'gr-modal-full', backdrop: false });
  }
  openModalCookies() {
    this.modalService.open(CookiesPolicyComponent,
      { windowClass: 'gr-modal-full', backdrop: false });
  }

  public openModalPrivacy() {
    this.modalService.open(PrivacyCenterComponent,
      { windowClass: 'gr-modal-full', backdrop: false });
  }

  public aboutLinkGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:home:footer',
      action: 'clicou',
      label: 'link:footer-sobre',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public contactLinkGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:home:footer',
      action: 'clicou',
      label: 'link:footer-contato',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public facebookLinkGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:home:footer',
      action: 'clicou',
      label: 'icon:footer-facebook',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public youtubeLinkGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:home:footer',
      action: 'clicou',
      label: 'icon:footer-youtube',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public instagramLinkGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:home:footer',
      action: 'clicou',
      label: 'icon:footer:instagram',
    };
    this.gtmService.pushTag(gtmTag);
  }

  // Footer
  public consultarLinkGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:home:footer',
      action: 'clicou',
      label: 'link:negociar-suas-dividas',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public viaLinkGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:home:footer',
      action: 'clicou',
      label: 'link:2-via',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public acordosLinkGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:home:footer',
      action: 'clicou',
      label: 'link:ver-acordos',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public cadastrarLinkGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:home:footer',
      action: 'clicou',
      label: 'link:cadastrar',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public entrarLinkGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:home:footer',
      action: 'clicou',
      label: 'link:entrar',
    };
    this.gtmService.pushTag(gtmTag);
  }
}
