import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';
import { AuthenticationService, CustomerSession } from 'src/app/services/authentication.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import {
  NgbCalendar,
  NgbDate,
  NgbDatepickerConfig,
  NgbDateStruct,
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import { DebtService } from 'src/app/services/debt.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Router } from '@angular/router';
import { ArrangementResponse } from '../arrangement-container/arrangement.types';
import { PaymentComponent } from '../payment/payment.component';


@Component({
  selector: 'app-cart-offers',
  templateUrl: './cart-offers.component.html',
  styleUrls: ['./cart-offers.component.scss']
})
export class CartOffersComponent implements OnInit, OnChanges {



  @Input() selectedDebts: any[];
  @Input() offer: any[]
  @Input() paymentMethod: number = null;
  @Input() offerId: number = null;
  listDebtsIds: any = []; // List of ids extracted from selectedDebts above

  //Generic
  loading = false;
  error = false;
  showMessage = false;
  customerInfo: CustomerSession;
  consolidatedOffer: any;

  @ViewChild('modalFinished') modalFinished: TemplateRef<any>;
  //Calendar
  disabledDates: any;
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  selectedDate: moment.Moment = this.utils.addBusinessDays(moment(), 1);// Default tomorrow (B. day)

  //for payment
  negotioation: any;
  confirmModal: any;
  isFinished = false;


  constructor(
    private authenticationService: AuthenticationService,
    private debtService: DebtService,
    private config: NgbDatepickerConfig,
    private calendar: NgbCalendar,
    private utils: UtilsService,
    private modalService: NgbModal,
    private router: Router,
    private paymentComponent: PaymentComponent

  ) {
    this.calendarConfiguration();
  }

  calendarConfiguration() {
    this.disabledDates = (date: NgbDate) => this.calendar.getWeekday(date) >= 6;
    this.config.firstDayOfWeek = 0;
    this.config.navigation = 'select';

    const current = moment();
    // If Sunday +0 days, If Saturday +1 days, Else +2 days (sum amount of weekenddays)
    const weekendsMath =
      current.weekday() == 7 ? 0 : current.weekday() == 6 ? 1 : 2;

    const maxDateProposal = current
      .clone()
      .add(environment.DiasLimitePagamento + weekendsMath, 'days');

    this.minDate = {
      year: Number(current.format('YYYY')),
      month: Number(current.format('M')),
      day: Number(current.format('D')),
    };

    this.maxDate = {
      year: Number(maxDateProposal.format('YYYY')),
      month: Number(maxDateProposal.format('M')),
      day: Number(maxDateProposal.format('D')),
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDebts?.currentValue) {
      changes.selectedDebts?.currentValue.map(debt => { debt.delay = this.utils.getDelayDate(debt); });
      this.onChangeDebts(changes.selectedDebts?.currentValue);
    }
  }

  onChangeDebts(debts: any) {
    this.listDebtsIds = [];
    debts.map(debt => { this.listDebtsIds.push(debt.DebtId); });
    this.getMultipleDebt(this.listDebtsIds);
  }

  ngOnInit(): void {
    this.getCustomerInfo();

  }

  async getCustomerInfo() {
    this.customerInfo = await this.authenticationService.getCustomerInfo();
  }

  updateSelectedDate(ev) {
    this.selectedDate = moment(ev).subtract(1, 'month');
    this.getMultipleDebt(this.listDebtsIds);
  }

  getMultipleDebt(id: number[]) {
    this.loading = true;
    this.debtService
      .GetMultipleDebtsOffer(this.selectedDate.format("YYYY/MM/D"), id)
      .then((res: any) => {
        this.consolidatedOffer = res.PaymentDetails;

        localStorage.setItem('payment', JSON.stringify(res))
        this.paymentComponent.updatePaymentDetail();


        this.loading = false;
      }).catch((error) => {
        this.loading = false;
      }
      );
  }

  preventDefault(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  openModal(content) {
    this.modalService.open(content, { centered: true, size: 'md' });
  }
  openModalFinish(data: ArrangementResponse) {
    let modal = this.modalService.open(this.modalFinished, { centered: true, size: 'sm' });
    setTimeout(() => {
      modal.close();
      this.router.navigate(['/painel/acordo/', data.ArrangementID]);
    }, 4000)
  }

  closeModal() {
    this.modalService.dismissAll(this.confirmModal)
  }


  public closeAgreement() {
    this.loading = true;
    this.closeModal();
    this.debtService
      .closeAgreement({
        debts: this.listDebtsIds,
        negotiationOfferID: this.offerId,
        paymentSource: Number(this.paymentMethod),
        paymentMethod: Number(this.paymentMethod),
        firstInstallmentDate: this.selectedDate.format("YYYY-MM-DD"),
        sourceType: 0,
        sourceCode: 0
      })
      .subscribe(
        (data: ArrangementResponse) => {
          this.loading = false;
          this.openModalFinish(data);
          localStorage.removeItem('payment')
          localStorage.removeItem('debts')
        },
        (err) => {
          this.loading = false;

        });
  }


}
