<div class="container-fluid">
  <div class="row">
    <div class="grid-main-agreement  pr-0 pb-3 col-md-8">
      <div class="flex-container justify-content-between privacy-row">
        <div class="d-flex justify-content-start mt-3">
          <div class="row justify-content-start">
            <div class="col-auto">
              <button class="btn btn-light btn-rollback" routerLink="/painel" type="button">
                <em class="fa fa-arrow-left fa-1x"></em>
                {{ 'BUTTONS.RETURN' | translate }}
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-4 mb-4 mr-0 privacy-wrap hide-if-mobile">
          <img class="lock-icon" src="../../../assets/images/lock.png">
          <h4 class="privacy-title"> {{ 'AGREEMENTS.PAYMENT.PRIVACY-TITLE' |
            translate }}</h4><br>
          <p class="privacy-subtitle">{{ 'AGREEMENTS.PAYMENT.PRIVACY-SUBTITLE' |
            translate }}</p>
        </div>
      </div>
      <hr>

      <div class="container-fluid mt-5">
        <ngb-accordion #accordion="ngbAccordion" (panelChange)="onPanelChange($event)"
          [activeIds]="paymentDebts.length <=1 ? 'panel1' : '' " class="range-accordion payment-accordion">
          <ngb-panel id="panel1" [disabled]="paymentDebts.length > 1 ? true : false">

            <ng-template ngbPanelTitle>
              <div class=" flex-container justify-content-start">
                <div class="d-flex icon-input"><img src="../../../assets/images/empty-wallet.png"></div>
                <div class="d-flex justify-content-start payment-text ml-1"> {{ 'AGREEMENTS.PAYMENT.PAYMENT-METHOD'
                  |
                  translate }}</div>

                <div class="d-flex justify-content-end payment-text ml-30" *ngIf="paymentDebts.length <= 1">
                  {{paymentMethodInput ? paymentMethodInput : ''}}</div>

                <div class="d-flex justify-content-end payment-text ml-30" *ngIf="paymentDebts.length > 1">Boleto
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="flex-container payment-input" *ngFor="let item of paymentMethods">
                <label id="inputMethod">
                  <input type="radio" name="radioset" [(ngModel)]="paymentMethod" [value]="item.PaymentMethod"
                    (change)="getMethodInput(item.MethodAlias); accordion.collapseAll()" />
                  <span class="payment-span">{{item.MethodAlias}}</span><br>
                  <small class="method-definition" *ngIf="item.MethodAlias == 'PIX'">{{ 'AGREEMENTS.PAYMENT.PIX' |
                    translate }}</small>
                  <small *ngIf="item.MethodAlias == 'Boleto'" class="method-definition">{{ 'AGREEMENTS.PAYMENT.BILLET' |
                    translate }}</small></label>

              </div>

            </ng-template>
          </ngb-panel>
          <ngb-panel id="panel2">
            <ng-template ngbPanelTitle>
              <div class="flex-container justify-content-start">
                <div class="d-flex icon-input"><img src="../../../assets/images/moneys.png"></div>
                <div class="d-flex justify-content-start payment-text ml-1">{{ 'AGREEMENTS.PAYMENT.INSTALLMENT' |
                  translate }}</div>
                <div class="d-flex justify-content-end payment-text ml-30">{{selectedOfferDescription ?
                  selectedOfferDescription :
                  ''}}</div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="flex-container payment-input">
                <select id="selectElement" class="installment-input" aria-placeholder="Selecione"
                  [(ngModel)]="selectedOfferDescription" (change)="saveSelectedValue($event); accordion.collapseAll()">
                  <option option disabled [selected]="!selectedOfferDescription" value="">
                    {{ 'AGREEMENTS.PAYMENT.INSTALLMENT-PLACEHOLDER' |
                    translate }}
                  </option>
                  {{paymentDetail.NegotiationOffers | json}}
                  <option *ngFor="let installment of paymentDetail.NegotiationOffers"
                    [value]="installment.OfferDescription">
                    {{ installment.OfferDescription }} (Desc. {{ installment.OfferDiscount }})
                  </option>
                </select>
              </div>

            </ng-template>
          </ngb-panel>
          <ngb-panel id="panel3">
            <ng-template ngbPanelTitle>

              <div class="flex-container justify-content-start">
                <div class="d-flex icon-input"><img src="../../../assets/images/discount-shape.png"></div>
                <div class="d-flex justify-content-start payment-text ml-1"> {{ 'AGREEMENTS.PAYMENT.COUPON' | translate
                  }}</div>
                <div class="d-flex justify-content-end payment-text ml-30"></div>

              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div>

                <div class="input-group coupon-input">
                  <input type="text" [(ngModel)]="coupon" class="form-control discout-coupon" id="cupom"
                    placeholder="{{ 'AGREEMENTS.PAYMENT.COUPON-PLACEHOLDER' | translate }}" formControlName="CouponCode"
                    aria-label="Coupon" aria-describedby="basic-addon2">

                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary coupon-btn" type="button">{{'AGREEMENTS.PAYMENT.COUPON-BTN'
                      | translate }}</button>
                    <!-- <button class="btn btn-outline-secondary coupon-btn"  type="button"
                     >Remover</button> -->
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>

      <button [disabled]="paymentMethod === null || selectedOfferID === null" class="btn btn-primary"
        id="btn-agreement-next" (click)="openModal(modalConfirm)">
        {{ 'AGREEMENTS.PAYMENT.CONFIRMATION-TITLE' | translate
        }}
        <em class="fa fa-arrow-right fa-1x"></em>
      </button>
    </div>


    <!-- cart -->
    <div class="col-md-4 pr-0 pl-0 cart-container">
      <app-cart-offers [selectedDebts]="paymentDebts" [paymentMethod]="paymentMethod"
        [offerId]="selectedOfferID"></app-cart-offers>
    </div>

    <hr>

  </div>

</div>

<ng-template #modalConfirm let-modal>
  <div class="modal-header">
    <h4 class="modal-title"><strong class="strong-title">{{ 'AGREEMENTS.PAYMENT.CONFIRMATION-TITLE' | translate
        }}</strong></h4>
    <img class="close-btn" (click)="modal.dismiss()" src="../../../assets/images/close-circle.png">
  </div>

  <div class="modal-body">
    <div class="modal-text">
      <p>{{ 'AGREEMENTS.PAYMENT.CONFIRMATION-BODY' | translate }}</p>
    </div>
    <div class="button-container">
      <button class="btn btn-primary d-block" (click)="closeAgreement();">{{
        'AGREEMENTS.PAYMENT.CONFIRMATION-BTN' |
        translate }}</button>
      <button class="cancel-btn" (click)="modal.dismiss()">{{ 'AGREEMENTS.PAYMENT.CANCEL' | translate }}</button>
    </div>

  </div>
</ng-template>